import RoutePath from 'App/RoutePath';
import {
  AuthWrapper,
  NewAuthWrapperBlock,
  AuthWrapperTitle,
} from 'LoggedOut/AuthWrapper';
import {
  StyledForm,
  StyledSubmitButton,
} from 'LoggedOut/SignUp/Forms/CommonFormStyledComponents';
import { validateEmail } from 'Shared/Form/Formik';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { Formik } from 'formik';
import React from 'react';
import { TextInput, Label } from '@unmind/design-system-components-web';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { rem } from 'polished';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { isMSTeams } from 'utils/MSTeams';
import { AuthTypeEnum } from '__generated__/globalTypes';
import { useHistory } from 'react-router-dom';
import { getOrganisationsByEmailDomain } from './__generated__/getOrganisationsByEmailDomain';

const LabelContainer = styled.div`
  margin-bottom: ${rem(8)};
  margin-top: ${rem(16)};
`;

export const FIND_ORGANISATON_BY_EMAIL_DOMAIN_QUERY = gql`
  query getOrganisationsByEmailDomain($email: String!) {
    organisationDomainsByEmailDomain(email: $email) {
      primarySubDomain
      id
      active
      authType
    }
  }
`;

type StartV2Form = {
  emailAddress: string;
};

export const StartV2 = () => {
  const { t: translate } = useTranslation('logged_out', {
    keyPrefix: 'start_v2',
  });
  const newStartRouteEnabled = useFeatureFlag(
    FEATURE_FLAGS.NEW_START_ROUTE_ENABLED,
  );
  const history = useHistory();

  const [findOrganisationByEmailDomain] =
    useLazyQuery<getOrganisationsByEmailDomain>(
      FIND_ORGANISATON_BY_EMAIL_DOMAIN_QUERY,
      {
        onCompleted: data => {
          if (
            !data.organisationDomainsByEmailDomain ||
            data.organisationDomainsByEmailDomain[0] === null
          ) {
            return;
          }

          if (data.organisationDomainsByEmailDomain.length === 0) {
            history.push(RoutePath.FindYourOrganisation);
          }

          const subdomain =
            data.organisationDomainsByEmailDomain[0].primarySubDomain;
          const authType = data.organisationDomainsByEmailDomain[0].authType;

          let redirectPath;

          if (isMSTeams()) {
            localStorage.setItem('subdomain', subdomain);
          }

          if (authType === AuthTypeEnum.AUTH0_SSO) {
            redirectPath = RoutePath.LoginWithSSO;
          } else if (authType === AuthTypeEnum.MIXED_MODE) {
            redirectPath = RoutePath.AuthMethod;
          } else {
            redirectPath = RoutePath.LoginWithCredentials;
          }

          window.location.assign(
            `${window.location.protocol}//${subdomain}.${process.env.REACT_APP_DOMAIN}${redirectPath}`,
          );
        },
      },
    );

  if (!newStartRouteEnabled) {
    history.push(RoutePath.StartSplitScreen);
  }

  const onSubmit = async (values: StartV2Form) =>
    findOrganisationByEmailDomain({
      variables: { email: values.emailAddress },
    });

  const initialValues: StartV2Form = {
    emailAddress: '',
  };

  return (
    <AuthWrapper newDesign>
      <NewAuthWrapperBlock>
        <AuthWrapperTitle>{translate('title')}</AuthWrapperTitle>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={({ emailAddress }) => {
            if (!emailAddress) {
              return {};
            }

            const emailAddressError = validateEmail(emailAddress);
            if (emailAddressError) {
              return { emailAddress: emailAddressError };
            }

            return {};
          }}
        >
          {({
            isValid,
            setFieldValue,
            errors,
            setTouched,
            touched,
            values,
          }) => (
            <StyledForm>
              <LabelContainer>
                <Label
                  htmlFor="emailAddress"
                  labelText={translate('email_field.label')}
                />
              </LabelContainer>
              <TextInput
                name="emailAddress"
                id="emailAddress"
                placeholder={''}
                aria-label={translate('email_field.a11y_label')}
                errorText={
                  errors.emailAddress && touched.emailAddress
                    ? errors.emailAddress
                    : undefined
                }
                onChange={e => {
                  setFieldValue('emailAddress', e.target.value);
                }}
                onBlur={() => setTouched({ emailAddress: true })}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setTouched({ emailAddress: true });
                  }
                }}
                data-testid="email-input"
              />
              <StyledSubmitButton
                data-testid="sign-in-button"
                label={translate('submit_button.label')}
                aria-label={translate('submit_button.a11y_label')}
                disabled={!isValid}
                onClick={async () => onSubmit(values)}
              />
            </StyledForm>
          )}
        </Formik>
      </NewAuthWrapperBlock>
    </AuthWrapper>
  );
};
