import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import { Redirect } from 'react-router';
import {
  getMSTeamsUserContext,
  isMSTeams,
  resolveMSTeamsDeepLink,
} from 'utils/MSTeams';
import { RESOLVED_DEEP_LINK_LOCAL_STORAGE_KEY } from 'utils/MSTeams/MSTeamsHelpers';
import { rem } from 'polished';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { BodyText, FeatureText } from '../../Shared/Typography';
import RoutePath from '../../App/RoutePath';
import { tracking } from '../../App/Tracking';
import { medium } from '../../utils';
import PrimaryButton from '../../Shared/PrimaryButton';
import SecondaryButton from '../../Shared/SecondaryButton';
import { default as BackgroundImage } from '../../Shared/Assets/start_screen_image.jpeg';
import { AlternativeMSTeamsStartPage } from './AlternativeMSTeamsStartPage';

const CreateAccountButton = styled(PrimaryButton)`
  width: 240px;
  margin-bottom: 10px;

  ${medium(css`
    margin-bottom: 0;
    margin-right: 10px;
  `)}
`;

const LoginButton = styled(SecondaryButton)`
  width: 240px;
  backdrop-filter: blur(${rem(12)});
`;

const Title = styled(FeatureText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize40],
  color: theme.colors.staticPalette.white,
}))`
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
`;

const Subtitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize24],
  color: theme.colors.staticPalette.white,
}))`
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
`;

const StartContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  ${medium(css`
    width: 33%;
  `)}
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;
  ${medium(css`
    flex-direction: row;
    margin-bottom: 40px;
  `)}
`;

const BackgroundWrapper = styled.div<{ backgroundImageSrc: string }>`
  background-image: url(${props => props.backgroundImageSrc});
  background-size: cover;
  background-position: center;
  transition: background-image 0.2s ease-in-out;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: absolute;
`;

export function Start() {
  const { t: translate } = useTranslation('logged_out');
  const context = getMSTeamsUserContext();
  const viewingInTeams = isMSTeams();
  const teamsHomePageExperimentFlag = useFeatureFlag<string>(
    FEATURE_FLAGS.TEAMS_HOME_PAGE_EXPERIMENT,
  );

  const shouldRemoveSubdomain = new URLSearchParams(location.search).get(
    'removeSubdomain',
  );

  if (shouldRemoveSubdomain) {
    localStorage.removeItem('subdomain');
  }

  if (viewingInTeams) {
    const redirectUrl = resolveMSTeamsDeepLink({
      userIsAuthenticated: false,
    });

    if (redirectUrl) {
      localStorage.setItem(RESOLVED_DEEP_LINK_LOCAL_STORAGE_KEY, 'true');

      return <Redirect to={redirectUrl} />;
    }
  }

  if (viewingInTeams) {
    tracking.track('teams-home-page-viewed', {
      tenantId: context?.user?.tenant?.id,
      MSTeamsUserId: context?.user?.id,
      abTestGroup: `${
        teamsHomePageExperimentFlag || 'non-experiment-start-page'
      }-sept-2024`,
    });
  }

  return viewingInTeams ? (
    <AlternativeMSTeamsStartPage />
  ) : (
    <BackgroundWrapper backgroundImageSrc={BackgroundImage} data-testid="start">
      <StartContent>
        <Title data-testid="form-title">
          {translate('getting_started.heading')}
        </Title>
        <Subtitle data-testid="form-subtitle">
          {translate('getting_started.subtitle')}
        </Subtitle>
        <ButtonsContainer>
          <CreateAccountButton
            role="button"
            variant="onDarkBg"
            data-testid={'create-an-account'}
            label={translate('getting_started.create_account_button.label')}
            to={`${RoutePath.SignUpPrivacy}`}
            onClick={() => {
              tracking.track('create-account-clicked', {
                partner: null,
              });
            }}
          />
          <LoginButton
            variant="onDarkBg"
            role="button"
            data-testid={'sign-in'}
            label={translate('getting_started.sign_in_button.label')}
            to={RoutePath.SignInToYourOrganisation}
            onClick={() => {
              tracking.track('signin-selected', {
                partner: null,
              });
            }}
          />
        </ButtonsContainer>
      </StartContent>
    </BackgroundWrapper>
  );
}
