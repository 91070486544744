/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AuthTypeEnum {
  AUTH0_SSO = "AUTH0_SSO",
  LEGACY_CREDENTIALS = "LEGACY_CREDENTIALS",
  MIXED_MODE = "MIXED_MODE",
}

export enum BreakdownPeriod {
  DAY = "DAY",
  MONTH = "MONTH",
  WEEK = "WEEK",
}

export enum ChatbotMessageRole {
  assistant = "assistant",
  user = "user",
}

export enum ContentProgress {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
}

export enum CourseStatus {
  ALL = "ALL",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum EventName {
  AI_COACH_CHATBOT_MESSAGE_SENT = "AI_COACH_CHATBOT_MESSAGE_SENT",
  APP_LOGGED_IN_FOCUSED = "APP_LOGGED_IN_FOCUSED",
  CHATBOT_MESSAGE_DISLIKED = "CHATBOT_MESSAGE_DISLIKED",
  CHATBOT_MESSAGE_LIKED = "CHATBOT_MESSAGE_LIKED",
  COLLEAGUE_INVITE_CTA_CLICKED = "COLLEAGUE_INVITE_CTA_CLICKED",
  CONTENT_DISLIKED = "CONTENT_DISLIKED",
  CONTENT_DOWNLOADED = "CONTENT_DOWNLOADED",
  CONTENT_LIKED = "CONTENT_LIKED",
  CONTENT_SHARED = "CONTENT_SHARED",
  HELP_RESOURCE_CLICKED = "HELP_RESOURCE_CLICKED",
  INTEGRATION_CARD_CLICKED = "INTEGRATION_CARD_CLICKED",
  INTEGRATION_REGISTERED_INTEREST = "INTEGRATION_REGISTERED_INTEREST",
  INVITE_LOVED_ONE_CTA_CLICKED = "INVITE_LOVED_ONE_CTA_CLICKED",
  NAVIGATED_TO_PAGE = "NAVIGATED_TO_PAGE",
  SEARCH_RESULT_SELECTED = "SEARCH_RESULT_SELECTED",
  SETTINGS_IN_APP_LANGUAGE_CHANGED = "SETTINGS_IN_APP_LANGUAGE_CHANGED",
  SHARED_CONTENT_VIEWED = "SHARED_CONTENT_VIEWED",
  TALK_BOOKING_FLOW_COMPLETED = "TALK_BOOKING_FLOW_COMPLETED",
  WELLBEING_TRACKER_STARTED = "WELLBEING_TRACKER_STARTED",
}

export enum FeedbackCategory {
  NEGATIVE = "NEGATIVE",
  NEUTRAL = "NEUTRAL",
  POSITIVE = "POSITIVE",
}

export enum FilterContext {
  ACTIVE_USERS = "ACTIVE_USERS",
  MANAGER_REPORTING = "MANAGER_REPORTING",
  WELLBEING_DATA = "WELLBEING_DATA",
}

export enum FindTeamSourceType {
  SIGNIN = "SIGNIN",
  SIGNUP = "SIGNUP",
}

export enum IndexBanding {
  High = "High",
  Low = "Low",
  LowMid = "LowMid",
  Mid = "Mid",
  MidHigh = "MidHigh",
}

export enum Mood {
  HAPPY = "HAPPY",
  OK = "OK",
  SAD = "SAD",
  SLIGHTLY_HAPPY = "SLIGHTLY_HAPPY",
  SLIGHTLY_SAD = "SLIGHTLY_SAD",
  VERY_HAPPY = "VERY_HAPPY",
  VERY_SAD = "VERY_SAD",
}

export enum MutationStatusCode {
  DUPLICATE_ENTRY = "DUPLICATE_ENTRY",
  ERROR = "ERROR",
  INVALID = "INVALID",
  SUCCESS = "SUCCESS",
}

export enum OrganisationAliasSearchStatusCode {
  ORG_ALIAS_NOT_FOUND = "ORG_ALIAS_NOT_FOUND",
  SUCCESS = "SUCCESS",
}

export enum Risk_Category {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
}

export enum TimePeriod {
  ALL_TIME = "ALL_TIME",
  LAST_12_WEEKS = "LAST_12_WEEKS",
  LAST_30_DAYS = "LAST_30_DAYS",
  LAST_6_MONTHS = "LAST_6_MONTHS",
}

export enum TokenInvalidMessage {
  INVITEES_LIMIT_REACHED = "INVITEES_LIMIT_REACHED",
  TOKEN_INVALID = "TOKEN_INVALID",
}

export enum ToolActivityEvent {
  TOOL_COMPLETED = "TOOL_COMPLETED",
  TOOL_PLAYED = "TOOL_PLAYED",
}

export enum UserAuthMethod {
  CREDENTIALS = "CREDENTIALS",
  SSO = "SSO",
}

export enum WellbeingTrackerBands {
  High = "High",
  Low = "Low",
  Mid = "Mid",
}

export interface ChatbotMessageFeedbackInput {
  messageId: string;
  conversationId: string;
}

export interface ChatbotMessageInput {
  id?: string | null;
  body: string;
  role: ChatbotMessageRole;
  metadata?: ChatbotMetadata | null;
}

export interface ChatbotMetadata {
  topic?: string | null;
  starterId?: string | null;
}

export interface CourseImpactQuestionnaireResponse {
  questionId: string;
  responseId: string;
}

export interface CreateCheckInInput {
  mood: string;
  feelingIds?: (number | null)[] | null;
  note?: string | null;
  clientSentAtUtcTimestamp?: string | null;
}

export interface CreateCompassInput {
  invitedEmails: string[];
  managerFname: string;
  ownerId: string;
  subdomain: string;
}

export interface CreateInvitedUserInput {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  subdomain: string;
  timezone: string;
  invitationToken: string;
  explicitEEADataStorageConsentGiven?: boolean | null;
  explicitPrivacyConsentGiven: boolean;
  explicitHealthDataConsentGiven?: boolean | null;
  marketingOptIn: boolean;
}

export interface DepartmentOption {
  departmentId: string;
  departmentName: string;
  questionId: string;
}

export interface ExploreSearchInput {
  after?: string | null;
  first?: number | null;
  searchTerm: string;
  searchSessionId?: string | null;
  clientSentAtUtcTimestamp?: string | null;
}

export interface FinancialImpactCalculatorInput {
  averageSalary: number;
  currency: string;
  financialImpactParamsId: number;
}

export interface GetUserCoursesInput {
  after?: string | null;
  first?: number | null;
  status?: CourseStatus | null;
}

export interface IndexRecommendationInput {
  band: IndexBanding;
  category: string;
}

export interface IsValidInvitationTokenInput {
  token: string;
}

/**
 * A generic key-value pair of strings
 */
export interface KeyPair {
  key: string;
  value: string;
}

export interface LocationOption {
  locationId: string;
  locationName: string;
  questionId: string;
}

export interface LogEventInput {
  eventName: EventName;
  eventProperties?: any | null;
  brazeProperties?: any | null;
}

export interface MergeTeamsBrazeProfilesInput {
  teamsUserId: string;
}

export interface NotificationPreferencesChoicesInput {
  communityParticipation?: boolean | null;
  upcomingEvents?: boolean | null;
  productUpdates?: boolean | null;
  calendarRecommendations?: boolean | null;
}

export interface OrganisationAliasSearchInput {
  searchTerm: string;
}

export interface OrganisationCheckInsInput {
  startDate?: any | null;
  endDate?: any | null;
  locationIds?: (string | null)[] | null;
  departmentIds?: (string | null)[] | null;
}

export interface OrganisationInsightsFiltersInput {
  startDate?: any | null;
  endDate: any;
  context?: FilterContext | null;
  timePeriod?: TimePeriod | null;
}

export interface OrganisationInsightsQueryInput {
  startDate?: any | null;
  endDate: any;
  locationIds?: (string | null)[] | null;
  departmentIds?: (string | null)[] | null;
  timePeriod?: TimePeriod | null;
}

export interface OrganisationSeriesInput {
  startDate?: any | null;
  endDate?: any | null;
  locationIds?: (string | null)[] | null;
  departmentIds?: (string | null)[] | null;
}

export interface OrganisationSignUpsInput {
  startDate?: any | null;
  endDate: any;
  locationIds?: (string | null)[] | null;
  departmentIds?: (string | null)[] | null;
  timePeriod?: TimePeriod | null;
}

export interface OrganisationToolsInput {
  startDate?: any | null;
  endDate?: any | null;
  locationIds?: (string | null)[] | null;
  departmentIds?: (string | null)[] | null;
}

export interface OrganisationWellbeingInput {
  startDate?: any | null;
  endDate: any;
  locationIds?: string[] | null;
  departmentIds?: string[] | null;
}

export interface QuestionnaireQuestionResponse {
  questionId: string;
  responseId: string;
}

export interface RecordCourseImpactQuestionnaireInput {
  courseId: string;
  responses: CourseImpactQuestionnaireResponse[];
  version: string;
  clientSentAtUtcTimestamp?: string | null;
}

export interface RecordSeriesQuestionnaireResponsesInput {
  responses: SeriesQuestionResponse[];
  isInitialQuestionnaire: boolean;
  seriesId: string;
  clientSentAtUtcTimestamp?: string | null;
}

export interface SaveUnmindIndexResponsesInput {
  questionnaireVersion: number;
  responses?: QuestionnaireQuestionResponse[] | null;
  clientSentAtUtcTimestamp?: string | null;
}

export interface ScheduleCompassIndexInput {
  clientId: string;
  cohortSize: number;
  compassIndex: CreateCompassInput;
  departmentOptions?: DepartmentOption[] | null;
  endDate: any;
  financialImpactCalculatorInput?: FinancialImpactCalculatorInput | null;
  locationOptions?: LocationOption[] | null;
  questionnaireId: string;
  startDate: any;
}

export interface SeriesQuestionResponse {
  seriesQuestionId: string;
  answer: number;
}

export interface SubmitChatbotMessagesInput {
  messages: ChatbotMessageInput[];
  conversationId?: string | null;
  clientSentAtUtcTimestamp?: string | null;
}

export interface SubmitFeedbackInput {
  source: string;
  category: FeedbackCategory;
  feedback: string;
  consentForContact: boolean;
  contentId?: string | null;
  contentSlug?: string | null;
  chatbotMessageFeedback?: ChatbotMessageFeedbackInput | null;
  tags?: string[] | null;
}

export interface UpdateActionTakenInput {
  indexScheduleId: string;
  actionId: string;
  markedAsTaken: boolean;
}

export interface UpdateIndexScheduleDatesInput {
  endDate: any;
  scheduleId: string;
  startDate: any;
}

export interface UpdateNotificationPreferencesInput {
  teamsNotificationPrefs?: NotificationPreferencesChoicesInput | null;
  emailNotificationPrefs?: NotificationPreferencesChoicesInput | null;
}

export interface UpdateUserInput {
  country?: string | null;
  createPlusOneVisited?: boolean | null;
  departmentId?: string | null;
  emailAddress?: string | null;
  explicitEEADataStorageConsentGiven?: boolean | null;
  explicitPrivacyConsentGiven?: boolean | null;
  explicitHealthDataConsentGiven?: boolean | null;
  firstName?: string | null;
  lastName?: string | null;
  locationId?: string | null;
  marketingOptIn?: boolean | null;
  notifiedNewLocales?: string | null;
  plusOneCTADismissed?: boolean | null;
  onboardingStatus?: string | null;
  state?: string | null;
  timezone?: string | null;
  userLocale?: string | null;
  userLocaleOriginal?: string | null;
  avatar?: string | null;
  botId?: string | null;
  currentPassword?: string | null;
  dob?: string | null;
  downloadOverMobileData?: string | null;
  emailNotifications?: boolean | null;
  lastAgreedPrivacyPolicyVersion?: string | null;
  lastAgreedTermsVersion?: string | null;
  mobile?: string | null;
  password?: string | null;
  platformNotifications?: boolean | null;
  praiseDirectory?: boolean | null;
  tutorialCompleted?: boolean | null;
}

export interface UpdateUserPasswordInput {
  currentPassword: string;
  newPassword: string;
}

export interface UserIdentifierInput {
  fieldKey?: string | null;
  fieldValue?: string | null;
}

export interface ValidateCustomIdentifierInput {
  subdomain: string;
  customIdentifiers?: (UserIdentifierInput | null)[] | null;
}

export interface ValidateVirginPulseEligibilityInput {
  subdomain: string;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: any | null;
}

export interface ValidateWorkplaceEmailInput {
  subdomain: string;
  email: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
