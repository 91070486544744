import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { isMSTeams } from 'utils/MSTeams';
import RoutePath from 'App/RoutePath';
import BackButton from 'Shared/Button/BackButton';
import SecondaryButton from 'Shared/SecondaryButton';
import { useHistory } from 'react-router';
import { getAuthErrorMessage } from '../Login/AuthError';
import { AlertBox } from '../../Shared/Form';
import {
  AuthContent,
  AuthWrapperFooter,
  AuthWrapperGrid,
  SubdomainHeader,
  AlertContainer,
  Container,
  LinksContainer,
  LockIcon,
  HelpIcon,
  Link,
  LinkText,
} from './AuthWrapper.styled';
import { AuthWrapperHelpCentre } from './AuthWrapperHelpCentre';

export { AuthWrapperHelpCentre } from './AuthWrapperHelpCentre';

interface AuthWrapperProps {
  children: ReactNode;
  subdomain?: string;
  progressBar?: ReactNode;
  showFooter?: boolean;
  newDesign?: boolean;
}

export const AuthWrapper = ({
  children,
  subdomain,
  progressBar,
  showFooter = true,
  newDesign = false,
}: AuthWrapperProps) => {
  const urlSearchParams = new URLSearchParams(location.search);
  const { errorCode, errorMessage } = getAuthErrorMessage(urlSearchParams);
  const { t: translate } = useTranslation('logged_out');
  const history = useHistory();

  return (
    <AuthWrapperGrid>
      <AuthContent newDesign={newDesign}>
        <Container>
          {/* MS teams users can't tab backwards so they need to be shown a clickable button */}
          {isMSTeams() && (
            <BackButton linkToDefault={RoutePath.StartSplitScreen} />
          )}
          {subdomain ? (
            <SubdomainHeader data-testid="business-pill">
              {subdomain}
            </SubdomainHeader>
          ) : null}
        </Container>
        {errorCode ? (
          <AlertContainer>
            <AlertBox
              alertType="failed"
              // the below translate function expects a TemplateStringsArray typed value, however casting errorMessage to that type
              // doesn't stop the linter complaining, so we have to cast as any here
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              message={translate(errorMessage as any)}
            />
          </AlertContainer>
        ) : null}
        {progressBar}
        {children}
        {showFooter &&
          (newDesign ? (
            <AuthWrapperFooter>
              <SecondaryButton
                label={translate('footer.create_account_button.label')}
                onClick={() => {
                  history.push(
                    subdomain
                      ? RoutePath.SignUp
                      : RoutePath.SignInToYourOrganisation,
                  );
                }}
              />
              <LinksContainer>
                <Link href={translate('footer.help.link')}>
                  <HelpIcon />
                  <LinkText>{translate('footer.help.label')}</LinkText>
                </Link>
                <Link href={translate('footer.privacy_policy.link')}>
                  <LockIcon />
                  <LinkText>
                    {translate('footer.privacy_policy.label')}
                  </LinkText>
                </Link>
              </LinksContainer>
            </AuthWrapperFooter>
          ) : (
            <AuthWrapperFooter>
              <AuthWrapperHelpCentre />
            </AuthWrapperFooter>
          ))}
      </AuthContent>
    </AuthWrapperGrid>
  );
};
