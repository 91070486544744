import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { RouteChildrenProps } from 'react-router';
import { Link } from 'react-router-dom';
import RoutePath from '../../App/RoutePath';
import {
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
  AuthWrapper,
} from '../AuthWrapper';
import {
  EmailConfirmationFooter,
  EmailConfirmationHelperText,
} from '../EmailConfirmation/EmailConfirmationShared';
import { EmailAddress } from '../EmailConfirmation/EmailConfirmation.styled';

function FindOrganisationConfirmation(props: RouteChildrenProps) {
  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');
  const email = props.location.state?.email || 'your email';
  const { isSignUpFlow } = props.location.state;
  const tryAgainLink = isSignUpFlow
    ? `${RoutePath.FindYourOrganisation}?path=signup`
    : RoutePath.FindYourOrganisation;

  return (
    <AuthWrapper>
      <AuthWrapperBlock data-testid="find-organisation-confirmation-page">
        <AuthWrapperTitle data-testid="confirmation-title">
          {translate('sign_up.find_organisation_confirmation.heading')}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle data-testid="confirmation-subtitle">
          {Trans({
            t: translate,
            i18nKey: 'sign_up.find_organisation_confirmation.subtitle',
            values: { email },
            defaults:
              "You'll get an email from us any moment now. We've sent it to <email_address>{{email}}</email_address>",
            components: { email_address: <EmailAddress /> },
          })}
        </AuthWrapperSubtitle>
        <AuthWrapperContent>
          <EmailConfirmationHelperText>
            {Trans({
              t: translate,
              i18nKey: 'sign_up.find_organisation_confirmation.helper_text',
              defaults:
                'Wrong email? <confirmation_link>Try again</confirmation_link>',
              components: {
                confirmation_link: (
                  <Link data-testid="try-again-link" to={tryAgainLink} />
                ),
              },
            })}
          </EmailConfirmationHelperText>
          <EmailConfirmationFooter userEmailAddress={email} />
        </AuthWrapperContent>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
}

export default FindOrganisationConfirmation;
